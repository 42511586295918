<template>
  <AccordionRows
    :entities="entities"
    :fields="fieldRows"
    :isPending="currentIsPending"
  />
</template>
<script>
import { computed } from "vue-demi";
import AccordionRows, { entityRow, entityRowField } from "./AccordionRows.vue";
import { computedRef, getSourceFacilityCode } from "../../utils/composables";
import { useAverageWeeklyDemand } from "../../store/averageWeeklyDemand.pinia";
// import PartFilesViewer from "../PartFilesViewer.vue";
import { isRegionUS } from "../../utils/variables";

export default {
  name: "PurchasedPartAccordion",
  props: {
    node: {
      default: null,
    },
    paneInfo: {
      default: null,
    },
    isPending: {
      default: () => ({}),
    },
  },
  components: {
    AccordionRows,
  },
  setup(props) {
    console.warn("PurchasedPartAccordion.vue");
    const averageWeeklyDemandStore = useAverageWeeklyDemand();

    const averageWeeklyDemandParams = computedRef(() => {
      return {
        query: {
          part_id: props.node.part.id,
        },
      };
    });

    const {
      total: totalAvgWeekly,
      results: awdResults,
      facilities: awdFacilities,
      isPending: isPendingAWD,
    } = averageWeeklyDemandStore.bundledAWD(averageWeeklyDemandParams);

    const propNodePart = computed(() => {
      return props.node?.part;
    });
    const sourceFacilityCode = getSourceFacilityCode(propNodePart);

    const annualForecast = computed(() => {
      return {
        annual_forecast: props.node.part.annual_forecast / 4,
      };
    });

    const currentIsPending = computed(() => {
      return {
        ...props.isPending,
        uom: props.isPending.part,
        partType: props.isPending.part,
        commodity: props.isPending.part,
        typeCode: props.isPending.part,
        sourceFacilityCode: isPendingAWD.value,
        facilityDemand: isPendingAWD.value,
        averageWeeklyDemand: isPendingAWD.value,
        totalAvgWeeklyDemand: isPendingAWD.value,
        annualForecast: props.isPending.part,
        inventoryManagementPolicy: props.isPending.part,
      };
    });

    const entities = computed(() => {
      return {
        part: props.node.part,
        uom: props.node.part.uom,
        partType: props.node.part.part_type,
        commodity: props.node.part.commodity,
        typeCode: props.node.part.type_code,
        sourceFacilityCode: sourceFacilityCode.value,
        facilityDemand: awdFacilities.value, // arrayItem
        averageWeeklyDemand: awdResults.value, // arrayItem
        totalAvgWeeklyDemand: totalAvgWeekly.value,
        annualForecast: annualForecast.value,
        inventoryManagementPolicy: props.node.part?.inventory_management_policy,
      };
    });

    const fieldRows = computed(() => {
      return [
        entityRow(
          "Purchased Part",
          [
            entityRowField("part", "part_number", { label: "Part Number" }),
            entityRowField("part", "description", { label: "Description" }),
            !isRegionUS
              ? entityRowField("part", "german_description", {
                  label: "German Description",
                })
              : null,
            entityRowField("part", "status", { label: "Status" }),
            entityRowField("uom", "description", { label: "UOM Description" }),
            entityRowField("partType", "description", {
              label: "Part Type Description",
            }),
            entityRowField("commodity", "description", {
              label: "Commodity Description",
            }),
            entityRowField("typeCode", "description", {
              label: "Type Code Description",
            }),
            entityRowField("part", "source_code", { label: "Source Code" }),
            entityRowField("sourceFacilityCode", "code", {
              label: "Source Facility",
            }),
            entityRowField("part", "mrp", { label: "MRP", type: "checkbox" }),
            entityRowField("part", "order_qty", {
              label: "Order Quantity",
            }),
            entityRowField("annualForecast", "annual_forecast", {
              label: "AQD",
            }),
            !isRegionUS
              ? entityRowField("inventoryManagementPolicy", "description", {
                  label: "Policy",
                })
              : null,
            entityRowField("part", "order_time", {
              label: "Order Time",
            }),
            entityRowField("part", "lead_time", {
              label: "Lead Time",
            }),
            entityRowField("part", "store_time", {
              label: "Store Time",
            }),
            entityRowField("part", "shelf_life", {
              label: "Shelf Life",
            }),
            entityRowField("part", "sds_flag", {
              label: "Part SDS Flag",
              type: "checkbox",
            }),
            entityRowField("part", "production_notes", {
              type: "textarea",
              label: "Production Notes",
              colAttributes: {
                cols: 12,
                sm: 12,
                class: "px-2",
              },
            }),
            entityRowField("part", "production_alert", {
              label: "Production Alert",
              type: "textarea",
              colAttributes: {
                cols: 12,
                sm: 12,
                class: "px-2",
              },
            }),
          ].filter((item) => item !== null)
        ),
        entityRow(
          "Average Weekly Demand",
          [
            entityRowField("averageWeeklyDemand", null, {
              type: "averageWeeklyDemand",
              includeEntities: true,
            }),
          ],
          {
            showLabel: true,
          }
        ),
        // entityRow("Files", [], {
        //   component: PartFilesViewer,
        //   vBind: { partNumber: props.node.part?.part_number ?? null },
        // }),
      ];
    });

    return {
      entities,
      fieldRows,
      currentIsPending,
    };
  },
};
</script>
