import { computed } from "vue-demi";
import { hasIn, get, sortBy } from "lodash-es";
import { defineStore } from "./store.pinia";
import { api } from "../utils/feathers-api";
import { useFind } from "../utils/feathers-pinia/lib";
import { useFacilities } from "./facilities.pinia";

const servicePath = "average-weekly-demand";
const useAverageWeeklyDemand = defineStore({
  idField: "id",
  clients: { api },
  servicePath,
  getters: {
    byId: (state) => (averageWeeklyDemandId) => {
      return state.items.filter((item) => item.id === averageWeeklyDemandId);
    },
  },
  actions: {
    bundledAWD(paramsRef) {
      const facilitiesStore = useFacilities();

      const { items, isPending } = useFind({
        model: this.Model,
        params: paramsRef,
        // When querying by part_id, make sure it's not empty
        queryWhen: computed(() => {
          return hasIn(paramsRef.value, "query.part_id")
            ? !!get(paramsRef.value, "query.part_id")
            : true;
        }),
      });

      // Get (valid) results
      const results = computed(() => {
        return items.value.filter((awd) => parseFloat(awd.weekly_demand) > 0.0);
      });

      // Get total and all facility ids
      const total = computed(() => {
        const facilityIds = results.value.map((item) => item.facility_id);
        const total = results.value.reduce(
          (acc, cur) => acc + parseFloat(cur.weekly_demand),
          0
        );
        return {
          total,
          facilityIds,
        };
      });

      // Get facility codes
      const unsortedfacilities = computed(() =>
        facilitiesStore.items.filter((i) =>
          total.value.facilityIds.includes(i.id)
        )
      );

      // sort by facility code
      const facilities = computed(() => {
        return sortBy(unsortedfacilities.value, ["code"]);
      });

      return {
        items,
        isPending,
        results,
        total,
        facilities,
      };
    },
  },
});

api.service(servicePath).hooks({});

export { useAverageWeeklyDemand };
